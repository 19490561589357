import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./TokenInput.module.css";
import { cls } from "../../utils/frontend/utils";

class TokenInput extends Component {
  constructor(props) {
    super(props);
    const { maxLength = 1 } = props;

    this.maxLength = typeof maxLength === "number" ? Math.max(1, maxLength) : 1;

    const handlers = [
      "onInputChanged",
      "onInputFilled",
      "onInputEmptied",
      "onEmptyBackspace",
      "onInputAfterFilled",
      "onShiftLeft",
      "onShiftRight",
      "onPaste",
    ];

    handlers.forEach((handler) => (this[handler] = this.resolveInputHandler(handler)));

    this.state = { value: "" };
  }

  resolveInputHandler = (handler) => {
    const { [handler]: handlerFn = (f) => f } = this.props;
    return typeof handlerFn === "function" ? handlerFn : (f) => f;
  };

  shouldComponentUpdate(prevState, nextState) {
    return prevState.value !== nextState.value;
  }

  inputIsEmpty = () => this.state.value.length === 0;

  inputIsFilled = () => this.state.value.length === this.maxLength;

  changeInputValue = (newValue) => {
    const maxlength = this.maxLength;
    const value = newValue.replace(/[^0-9]/gi, "");

    if (value.length <= maxlength) {
      this.setState({ value }, () => this.onInputChanged(value));

      if (value.length === 0) return this.onInputEmptied();
      if (value.length === maxlength) return this.onInputFilled(value);
    }

    return this.onInputAfterFilled(value.substr(0, maxlength), value.substr(maxlength));
  };
  // Used for onPaste function, just update the state value directly
  changeInputValueWOSideEffect = (newValue) => {
    const maxlength = this.maxLength;
    const value = newValue.replace(/[^0-9]/gi, "");

    if (value.length <= maxlength) {
      this.setState({ value }, () => this.onInputChanged(value));
    }
  };
  handleKeyUp = (evt) => {
    evt.persist();
    const value = evt.target.value.replace(/[^0-9]/gi, "");
    const cursorPosition = evt.target.selectionStart;
    const keycode = evt.keyCode;

    const isLeft = keycode === 37;
    const isRight = keycode === 39;
    const isEmpty = value.length === 0;
    const isBackspace = keycode === 8;

    if (isEmpty && isBackspace) return this.onEmptyBackspace();

    if ((isEmpty || cursorPosition === 0) && isLeft) return this.onShiftLeft();

    if ((isEmpty || cursorPosition === value.length) && isRight) return this.onShiftRight();
  };

  handleChange = (evt) => this.changeInputValue(evt.target.value);

  handleFocus = (evt) => {
    const value = evt.target.value;
    this.setState({ value: "" }, () => this.setState({ value }));
  };

  handlePaste = (evt) => {
    const value = evt.clipboardData.getData("text");
    evt.preventDefault();
    return this.onPaste(value);
  };
  render() {
    /* eslint-disable no-unused-vars */
    const {
      type,
      value,
      onChange,
      onFocus,
      onKeyUp,
      placeholder,
      autoComplete,
      maxLength,
      onInputChanged,
      onInputFilled,
      onInputEmptied,
      onEmptyBackspace,
      onInputAfterFilled,
      onShiftLeft,
      onShiftRight,
      onPaste,
      ...restProps
    } = this.props;
    /* eslint-enable */
    return (
      <div className={cls(styles, ["token-input-container"])}>
        <input
          type="text"
          style={{ textAlign: "center" }}
          ref={(elem) => (this.inputField = elem)}
          value={this.state.value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyUp={this.handleKeyUp}
          onPaste={this.handlePaste}
          autoComplete="off"
          {...restProps}
        />
      </div>
    );
  }
}

TokenInput.propTypes = {
  maxLength: PropTypes.number,
  onInputChanged: PropTypes.func,
  onInputFilled: PropTypes.func,
  onInputEmptied: PropTypes.func,
  onEmptyBackspace: PropTypes.func,
  onInputAfterFilled: PropTypes.func,
  onShiftLeft: PropTypes.func,
  onShiftRight: PropTypes.func,
  onPaste: PropTypes.func,
};

export default TokenInput;
