import { ExclamationCircleOutlined, EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";
import styled from "styled-components";
import { COLORS } from "../../const";
import { useCustomBlurHandler } from "../../hooks/useCustomBlurHandler";
import { cls } from "../../utils/frontend/utils";
import styles from "./index.module.css";

const IconStyle = {
  color: COLORS["gray-7"],
};

export default function PasswordVisibilityIcon({
  isPasswordVisible,
  setIsPasswordVisible,
  ...props
}) {
  return isPasswordVisible ? (
    <EyeInvisibleFilled
      style={IconStyle}
      className={cls(styles, ["visible-icon"])}
      onClick={() => setIsPasswordVisible((prev) => !prev)}
      {...props}
    />
  ) : (
    <EyeFilled
      style={IconStyle}
      className={cls(styles, ["visible-icon"])}
      onClick={() => setIsPasswordVisible((prev) => !prev)}
      {...props}
    />
  );
}

const PasswordContainer = styled.div`
  .anticon {
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: block;
    }
  }
`;

/**
 * Toggles between the "show password" or error icons when hovering over the error icon. For use with an Input suffix icon.
 * Due to needing to manage state and input element focus, this was extracted into a hook.
 */
export function useShowPasswordOrErrorIcon(
  hasError: boolean,
  handleInputBlur = () => {},
  /** Element that contains elements the user may click on - when user clicks anything inside this, it won't call handleInputBlur */
  parentSelector = undefined
): {
  /** Toggles true/false depending on if view password is clicked */
  isPasswordVisible: boolean;
  /** Give this to your view password button */
  setIsPasswordVisible: Dispatch<SetStateAction<boolean>>;
  /** Renders either the error icon or password icon */
  ErrorOrShowPasswordIcon: ReactElement;
  /** Give this to your element's **onKeyDown** so that pressing tab also triggers `handleBlur` */
  handleTabKeyBlur: (e) => void;
} {
  // Variables
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTabKeyBlur = useCustomBlurHandler(parentSelector, handleInputBlur);
  const [eyeHovered, setEyeHovered] = useState(false);
  // Icons
  const ErrorIcon = useMemo(
    () => (
      <ExclamationCircleOutlined
        className={cls(styles, ["error-icon"])}
        onMouseOver={() => setEyeHovered(true)}
        onMouseLeave={() => setEyeHovered(false)}
      />
    ),
    []
  );
  const ViewPasswordIcon = useMemo(
    () => (
      <PasswordContainer>
        <PasswordVisibilityIcon
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVisible={setIsPasswordVisible}
          onMouseOver={() => setEyeHovered(true)}
          onMouseLeave={() => setEyeHovered(false)}
        />
      </PasswordContainer>
    ),
    [isPasswordVisible]
  );

  const ErrorOrShowPasswordIcon = useMemo(() => {
    if (eyeHovered) return ViewPasswordIcon;
    return !hasError ? ViewPasswordIcon : ErrorIcon;
  }, [ErrorIcon, ViewPasswordIcon, eyeHovered, hasError]);

  return {
    isPasswordVisible,
    setIsPasswordVisible,
    ErrorOrShowPasswordIcon,
    handleTabKeyBlur,
  };
}
