import { KeyboardEvent, useCallback, useEffect } from "react";

/**
 * **Custom onBlur handler**
 *
 * Avoids triggering the blur handler when user is clicking inside specified element.
 *
 * Note: this uses click and keydown handlers to implement a custom blur event;
 * there may be some side effects due to the fact that it is not a *real* blur event
 *
 * @returns handleTabKeyBlur: pass to `onKeyDown` to handle tab-key focus change
 *
 * **Example use case**
 * - You want to *hide a tooltip* when un-focusing an input field
 * - However, the input has elements inside/around it that can be clicked/focused
 *   - When clicking these elements, the *focus on the input field is lost!*
 *   - This is considered a *false positive* - you want the tooltip to stay open
 *
 * @example
 * Specify a parent element and your blur handler will only be called if the user clicks out of this element or uses tab to change focus
 * ```tsx
 * const handleTabKeyBlur = useCustomBlurHandler(
 *  ".parent-element",
 *  () => hideTooltip()
 * );
 * // ...
 * <div className="parent-element">
 *  <Input
 *    onKeyDown={handleTabKeyBlur}
 *    onFocus={() => showTooltip()}
 *    // don't use `onFocus` here - it's handled by the hook
 *  />
 *  <Button
 *    // we want the user to be able to click this button and keep tooltip visible
 *  />
 * </div>
 * ```
 */
export function useCustomBlurHandler(
  /** The target element that contains elements the user may click on - when user clicks anything inside this, it won't call handleBlur */
  targetSelector: string,
  /** Desired blur handler - will trigger this when we determine it's not a 'false-positive' blur */
  handleBlur: () => void
): (e: KeyboardEvent<HTMLInputElement>) => void {
  /** Give this to your element's **onKeyDown** so that pressing tab also triggers `handleBlur` */
  const handleTabKeyBlur = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Tab") {
        handleBlur();
      }
    },
    [handleBlur]
  );

  useEffect(() => {
    if (!targetSelector) return;
    function clickHandler(e: MouseEvent) {
      const parent = document.querySelector(targetSelector);
      if (!parent?.contains?.(e.target as Node)) {
        handleBlur?.();
      }
    }
    document?.body?.addEventListener("mousedown", clickHandler);
    return () => document?.body?.removeEventListener("mousedown", clickHandler);
  }, [handleBlur, targetSelector]);

  return handleTabKeyBlur;
}
